<template>
  <div class="mb-3 card">

    <div>
      <v-tabs v-model="tab">
        <v-tab key="list">
          {{ $t('erp.lang_nav_warelabels') }}
        </v-tab>
        <v-tab key="settings">
          {{ $t('settings.lang_settings') }}
        </v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item key="list">
            <v-container fluid>
              <v-layout>
                <v-flex md6 mr-2 ml-2>
                  <v-select outlined item-text="text" item-value="value" v-model="selectedServer"
                            :label="$t('settings.lang_PrintServer')"
                            :items="printServerOptions"
                  ></v-select>
                </v-flex>
                <v-flex md6 mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="labelQuantity"
                                :label="$t('generic.lang_change_quantity')"
                                autocomplete="off"
                                number
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout v-if="showSizes">
                <v-flex md12>
                  <v-select outlined item-text="name" item-value="value" v-model="selectedSize"
                            :label="$t('generic.lang_size')"
                            :items="sizes"></v-select>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex md12>
                  <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header"
                       v-if="selectedLabels && selectedLabels != null && selectedLabels.length > 0">
                    <v-chip
                        v-for="(label, index) in selectedLabels"
                        :key="'l-'+index"
                        v-model="selectedLabels[index].selected"
                        close
                        @click:close="removeSelectedLabel(label, index)">
                      {{label.name}}
                    </v-chip>
                  </div>
                  <div v-else :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
                    {{$t("erp.lang_nolabelselected")}}
                  </div>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex md6 mr-2 ml-2>
                  <v-btn block text color="error" @click="resetData">{{ $t('generic.lang_reset') }}
                  </v-btn>
                </v-flex>
                <v-flex md6 mr-2 ml-2>
                  <v-btn block color="success" @click="printData" :loading="loading" :disabled="loading">{{ $t('erp.lang_nav_warelabels') }}
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex md12>
                  <!-- Table -->
                  <Datatable ref="taxSetupDatatable"
                             :api-endpoint="ENDPOINTS.DATATABLES.ERP.PRINTWARELABEL"
                             :datatable-headers="datatableHeaders"
                             :searchPayload="searchPayload"
                             :datatable-attach-search="datatableAttachSearch"
                             :excel-columns="excelColumns"
                             @clickRow="rowClicked"
                             excel-file-name="PrintWareLabels"
                             :noExcelExport="true"
                             :show-select-checkbox="false"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>
          <v-tab-item key="settings">
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-checkbox
                    :true-value="1" :false-value="0"
                    :label="$t('erp.lang_displayPricesWithoutTax')"
                    v-model="settings.displayPricesWithoutTax"
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-select outlined item-text="text" item-value="value" v-model="settings.server"
                            :label="$t('erp.lang_defaultPrintServer')"
                            :items="printServerOptions"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select outlined item-text="name" item-value="value" v-model="settings.size"
                            :label="$t('generic.lang_defaultSize')"
                            :items="sizes"></v-select>
                </v-col>
                <v-col cols="12" align="right">
                  <v-btn @click="updateDefaultSettings" color="success" large> {{$t('generic.lang_save')}} </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </div>
</template>

<style>
.dataTables_filter {
  display: none;
}
</style>

<script>
import {ENDPOINTS} from '../../../config'
import {Events} from "../../../plugins/events";
import Datatable from "../../datatable/Datatable";
import mixin from "../../../mixins/KeyboardMixIns";
import FileSaver from 'file-saver';


export default {
  components: {
    Datatable
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      search: "",
      settings:{
        size:0,
        server:1,
        quantity:1,
        displayPricesWithoutTax: 0,
      },
      searchPayload: {},
      loading: false,
      loadingSettings: false,
      tab: 0,
      labelQuantity: 1,
      sizes:[
        {
          name:"nichts",
          value:0,
        },
        {
          name:this.$t('erp.lang_nav_warelabels')+" 31x22mm",
          value:'label_31x22/',
        },
        {
          name:this.$t('erp.lang_nav_warelabels')+" 32x25mm",
          value:'label_32x25/',
        },
        {
          name:this.$t('erp.lang_nav_warelabels')+" 32x25mm mit Herstelldatum",
          value:'label_32x25_production_date/',
        },
        {
          name:this.$t('erp.lang_nav_warelabels')+" 40x23mm",
          value:'label_40x23/',
        },
        {
          name:this.$t('erp.lang_nav_warelabels')+" 45x25mm",
          value:'label_45x25/',
        },
        {
          name:this.$t('erp.lang_nav_warelabels')+" 51x25mm",
          value:'label_51x25/',
        },
        {
          name:this.$t('erp.lang_nav_warelabels')+" 55x85mm",
          value:'label_55x85/',
        },
        {
          name:this.$t('erp.lang_nav_warelabels')+" 55x85mm mit Herstelldatum",
          value:'label_55x85_production_date/',
        },
        {
          name:this.$t('erp.lang_nav_warelabels')+" 58x43mm",
          value:'label_58x43/',
        },
        {
          name:this.$t('erp.lang_nav_warelabels')+" 69x38mm",
          value:'label_69x38/',
        },
        {
          name:this.$t('erp.lang_nav_warelabels')+" 38.1x25.4mm",
          value:'label_381x254/',
        },
        {
          name:this.$t('erp.lang_nav_warelabels')+" 76.2x50.8mm",
          value:'label_762x508/',
        },
      ],
      selectedSize:null,
      selectedLabels: [],
      // --- Datatable ---
      datatableHeaders: [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          width: 80,
          hide: true
        },
        {text: this.$t('erp.lang_ware_create_id'), value: "ean"},
        {text: this.$t('erp.lang_itemname'), value: "name"},
        {text: this.$t('erp.internal_wareNo'), value: "wareNo"},
        {text: this.$t('erp.lang_itemDimensions'), value: "deimensions"},
        {text: this.$t('erp.lang_LongVersion'), value: "longVersions", hide: true}
      ],
      excelColumns: [

        {
          label: "ID",
          field: 'id',
        },
        {
          label: this.$t('erp.lang_ware_create_id'),
          field: 1,
        },
        {
          label: this.$t('erp.lang_itemname'),
          field: 2,
        },
        {
          label: this.$t('erp.internal_wareNo'),
          field: 3,
        },
        {
          label: this.$t('erp.lang_itemDimensions'),
          field: 4,
        },
        {
          label: this.$t('erp.lang_LongVersion'),
          field: 5,
        }
      ],
      selectedServer: {},
      printServerOptions: [{
        value: {
          method: "pdf",
          https: "none",
          printserver: "none",
          printerAddress: "none"
        },
        text: "PDF",
      }],
      datatableAttachSearch: [
        {text: this.$t('generic.lang_waregroup'), value: 'itemGroup', searchType: 'multiselect', searchCol: /* put the column number of the waregroup here */4},
      ]
    }
  },
  computed:{
    showSizes(){
      return this.selectedServer?.method==='pdf'
    }

  },
  watch: {
    search: function () {
      this.dataTable.search(this.search).draw();
    },
  },

  methods: {
    async getSearchPayload() {
      let data = {};

      //ITEMGROUPS
      let itemgroups = await this.$store.dispatch("itemgroups/getItemgroups");

      data["itemGroup"] = itemgroups.map((itemgroup) => {
        return {
          id: itemgroup.id,
          name: itemgroup.name
        }
      }).sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      this.searchPayload = data;
    },
    printData: function () {
      let ids = [];
      let printServerAPIPath;
      let endpoint = ENDPOINTS.ERP.PRINTWARELABEL.PRINTPDF
      if (!(this.selectedLabels && this.selectedLabels.length > 0)) {
        return;
      }

      for (let i = 0; i < this.selectedLabels.length; i++) {
        if (this.selectedLabels[i].selected) {
          ids.push(this.selectedLabels[i].id);
        }
      }

      if (!(ids && ids.length > 0)) {
        return;
      }

      if (this.selectedServer === undefined || this.selectedServer.printserver === undefined) {
        return;
      }

      // CHECK IF WE SIZE IS SELECTED

      if(this.selectedSize){
        endpoint = ENDPOINTS.ERP.PRINTWARELABEL.PRINTPDFBYSIZE+this.selectedSize
      }

      this.selectedLabels = [];
      this.loading=true;
      this.axios.post(endpoint, {
        printMethod: this.selectedServer?.method,
        zebraPrinterTMPL: 1,
        labelQTY: parseInt(this.labelQuantity),
        itemsToPrint: JSON.stringify(ids)
      },{
        responseType: 'arraybuffer',
      }).then((res) => {

        if (this.selectedServer?.method === "pdf") {
          //GENERATE PDF CONTENT FOR iFRAME
          /*      const blobContent = new Blob([res.data], {type: "application/pdf"});
                this.iframePDFContent = URL.createObjectURL(blobContent);
                this.displayProjectDialog = true; */

          //DOWNLOAD INVOICE AS PDF
          FileSaver.saveAs(new Blob([res.data], {
            type: "application/pdf"
          }), "Label.pdf");
        } else {

          let https = this.selectedServer.https ? this.selectedServer.https : 'https';
          let printServerAddr = this.selectedServer.printserver;
          let printerAddress = this.selectedServer.printerAddress;

          if (printServerAddr === "localhost") {
            printServerAPIPath = https + "://" + printServerAddr + "/" + ENDPOINTS.ERP.PRINTWARELABEL.SEND.POS;
          } else {
            printServerAPIPath = https + "://" + printServerAddr + "/" + ENDPOINTS.ERP.PRINTWARELABEL.SEND.V3API;
          }

          this.axios.post(printServerAPIPath, {
            zebraPrinterXML: res.data[0],
            zebraPrinterIP: printerAddress
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_actionSuccessful'),
                color: "success"
              });

              this.resetData();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch(() => {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          });
        }
      }).catch((err) => {
        //console.log(err);
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(()=>{
        this.loading=false;
      });
    },
    resetData: function () {
      this.selectedLabels = []
    },
    rowClicked(entry) {
      //CHECK IF LABEL ALREADY IN SELECTION
      let label = this.selectedLabels.find((label) => {
        return label.id === parseInt(entry.id);
      });

      if(label)
        return;

      this.selectedLabels.push({
        id: parseInt(entry.id),
        selected: true,
        name: entry.name
      });
    //  console.log(this.selectedLabels,entry)
    },
    removeSelectedLabel(label, labelIndex) {
      this.$delete(this.selectedLabels, labelIndex);
    },
    getDefaultSettings(){
      this.loadingSettings=true;

      this.axios.post(ENDPOINTS.ERP.PRINTWARELABEL.SETTINGS.GET).then(res=>{
        if(res.status===200){
          this.selectedSize=res.data.deafultSizeLabel;
          this.selectedServer=this.printServerOptions.find(server=>res.data.defaultServerLabel===server.value.method)?.value;
          this.settings.size=res.data.deafultSizeLabel;
          this.settings.server=this.printServerOptions.find(server=>res.data.defaultServerLabel===server.value.method)?.value;
          this.settings.displayPricesWithoutTax = Number(res.data.displayPricesWithoutTax);
        }else{

          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err=>{

        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(()=>{
        this.loadingSettings=false;
      })
    },
    updateDefaultSettings(){
        this.loadingSettings=true;

      this.axios.post(ENDPOINTS.ERP.PRINTWARELABEL.SETTINGS.UPDATE,{
        deafultSizeLabel:this.settings.size,
        defaultServerLabel:this.settings.server?.method,
        displayPricesWithoutTax: this.settings.displayPricesWithoutTax,
      }).then(res=>{
        if(res.data.STATUS==='SUCCESS'){
          this.selectedSize=this.settings.size;
          this.selectedServer=this.settings.server;
        }else{
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err=>{

        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(()=>{
        this.loadingSettings=false;
      })
    }
  },
  mounted() {
    this.getSearchPayload();
    this.getDefaultSettings();
  }
}
</script>

<style>
label.v-label {
  margin-bottom: 0rem;
}
</style>